.target-details-page {
	padding: 0 .1rem .1rem 0;
	// overflow: hidden; On désactive 

	div[data-tabs-open="false"] {
		grid-column: 1 / 3;

		& + aside {
			grid-column: unset
		}
	}

	.page-header {
		h3 {
			margin: 0;
		}
	
		grid-column: 1 / 3;
	}
	
	.page-content {
		display: flex;
		flex-direction: row;
		gap: 1rem;
	
		& > *:first-child {
			width: 100%;
			flex-shrink: 1;
		}
	}

	aside {
		display: grid;
		grid-template-rows: auto 1fr;
		z-index: 1001;
		background-color: white;
		box-shadow: 0 0 .25rem rgba(0, 0, 0, .16);
		height: 100%;
		border-radius: .25rem;
	
		[role="tab"] {
			display: grid;
			grid-template-columns: repeat(2, auto);
	
			button {
				border-radius: .25rem;
				
				min-width: unset;
				min-height: unset;
				justify-self: end;
	
				svg {
					height: 1rem;
					max-height: 100%;
					aspect-ratio: 1;
					padding: 0;
				}
			}
		}
	
		[role="tabpanel"] {
			padding: 1rem;
			grid-template-rows: 3.5rem 3.5rem 1fr;
			grid-template-columns: 2fr 1fr;
			gap: 1rem;
			grid-template-areas:
				'statuses statuses'
				'summary summary'
				'histories contacts';
	
			height: 100%;
			overflow-y: hidden;
	
			
			&[aria-hidden="false"] {
				display: grid;
			}
	
			section.statuses {
				grid-area: statuses;
				align-self: center;
	
				ul {
					display: flex;
					gap: 1.5rem;
					padding: 0;
					margin: 0;
					list-style: none;
					align-items: center;
	
					button {
						height: fit-content;
					}
				}
			}
			section.summary {
				grid-area: summary;
				align-self: center;
				/*
					The rest of the styling for this section
					is in the _targetPartnerPartnerSummary.module.scss file
				*/
			}
	
			section.histories,
			section.contacts {
				display: grid;
				grid-template-rows: auto 1fr;
				gap: .5rem;
	
				padding: .5rem .25rem 0 0;
				overflow: hidden;
	
				ul {
					display: grid;
					grid-auto-rows: min-content;
					gap: .5rem;
	
					margin: 0;
					min-height: 0;
					max-height: 100%;
					overflow-y: auto;
		
					list-style: none;
	
					& > p {
						justify-self: center;
					}
				}
			}
	
			section.histories {
				grid-area: histories;
				
				button {
					box-shadow: 0 0 .25rem rgba(0, 0, 0, .16);
					margin-left: 2.5rem; // Sum of the li's grid's first column and its gap
					width: calc(100% - 2.5rem);
				}
	
				ul {
					padding: .5rem 0.25rem 1.5rem 0;
				}
			}
	
			section.contacts {
				grid-area: contacts;
				display: grid;
				grid-template-columns: 1fr auto;
				padding-left: 1rem;
				border-left: solid 1px var(--clr-alabaster-300);
	
				button > svg.loading-icon {
					display: none;
				}
	
				ul {
					grid-column: 1 / 3;
					align-items: flex-start;
					min-width: 10rem;	
					padding: .5rem 0 1rem 0;
				}
			}
		}
	}
	

	.targetPartner-details-list {
		min-width: 0;
	}
	
}